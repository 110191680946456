body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f9;
}
* {
  scroll-behavior: smooth;
}
@media only screen and (min-width: 930px) {
  html {
    width: 100vw;
    height: 100%;
    overflow: hidden;
  }
  body {
    overflow: hidden;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

* {
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

*::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 8px;
}

.BrainhubCarousel,
.BrainhubCarousel__trackContainer ul {
  overflow: visible !important;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer {
  overflow: visible !important;
  padding: 30px 0 10px 0;
}

.ads-product-list {
  max-height: 390px;
  overflow-y: auto;
}

button,
a,
#item,
#category,
.cancel-btn,
.language-list {
  cursor: pointer;
}

.lock {
  overflow: hidden;
}

.App {
  display: grid;
  height: 100vh;
}

@font-face {
  font-family: "HKConcentratePro";
  src: url(./Fonts/HKConcentratePro-Regular.woff2) format("woff2"),
    url(./Fonts/HKConcentratePro-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Verveine";
  src: url(./Fonts/VerveineW01Regular.woff2) format("woff2"),
    url(./Fonts/VerveineW01Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "playfair_displayregular";
  src: url(./Fonts/playfairdisplayregular-webfont.woff2) format("woff2"),
    url(./Fonts/playfairdisplayregular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Pro";
  src: url(./Fonts/ApercuPro-Bold.woff2) format("woff2"),
    url(./Fonts/ApercuPro-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Marcelle Script";
  src: local("Marcelle Script"),
    url(./Fonts/MarcelleScript.ttf) format("truetype");
}

@font-face {
  font-family: "barlow_condensedregular";
  src: url(./Fonts/barlowcondensed-regular-webfont.woff2) format("woff2"),
    url(./Fonts/barlowcondensed-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bree_serifregular";
  src: url(./Fonts/breeserif-regular-webfont.woff2) format("woff2"),
    url(./Fonts/breeserif-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "calistogaregular";
  src: url(./Fonts/calistoga-regular-webfont.woff2) format("woff2"),
    url(./Fonts/calistoga-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "caveatregular";
  src: url(./Fonts/caveat-regular-webfont.woff2) format("woff2"),
    url(./Fonts/caveat-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "comfortaaregular";
  src: url(./Fonts/comfortaa-regular_light-webfont.woff2) format("woff2"),
    url(./Fonts/comfortaa-regular_light-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "courgetteregular";
  src: url(./Fonts/courgette-regular-webfont.woff2) format("woff2"),
    url(./Fonts/courgette-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm_sansregular";
  src: url(./Fonts/dmsans-regular-webfont.woff2) format("woff2"),
    url(./Fonts/dmsans-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "exoregular";
  src: url(./Fonts/exo-regular-webfont.woff2) format("woff2"),
    url(./Fonts/exo-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fredoka_oneregular";
  src: url(./Fonts/fredokaone-regular-webfont.woff2) format("woff2"),
    url(./Fonts/fredokaone-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "indie_flowerregular";
  src: url(./Fonts/indieflower-webfont.woff2) format("woff2"),
    url(./Fonts/indieflower-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kalamregular";
  src: url(./Fonts/kalam-regular-webfont.woff2) format("woff2"),
    url(./Fonts/kalam-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lobsterregular";
  src: url(./Fonts/lobster-regular-webfont.woff2) format("woff2"),
    url(./Fonts/lobster-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "loraregular";
  src: url(./Fonts/lora-regular-webfont.woff2) format("woff2"),
    url(./Fonts/lora-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratregular";
  src: url(./Fonts/montserrat-regular-webfont.woff2) format("woff2"),
    url(./Fonts/montserrat-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "satisfyregular";
  src: url(./Fonts/satisfy-regular-webfont.woff2) format("woff2"),
    url(./Fonts/satisfy-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
